import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const LandingBackgroundZaplatomat = ({ children }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "landing-zaplatomat.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);

  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage Tag='section' {...bgImage} preserveStackingContext>
      {children}
    </BackgroundImage>
  );
};

export default LandingBackgroundZaplatomat;
